"use strict";
import * as e from './echart_utils.js';

import watermarkImage from '../assets/dotlake-transparent-high-quality.png';

var currentTheme = localStorage.getItem('theme') || 'light';
var chartsConfig = update_chartsConfig(currentTheme);

function update_chartsConfig(currentTheme){
  chartsConfig = [
    {
      elementId: 'inflation_referendums_tracker',
      jsonPath: 'data/inflation_referendums_tracker000000000000.jsonl',
      xName: 'referendum_name',
      yName: 'votes',
      title: 'Inflation Referendums Tracker',
      build: e.inflation_bar_chart
    }
  ];

  chartsConfig.forEach(config => {
    const chart = e.createEchartElement(config.elementId, currentTheme);
    if (chart) {
      e.loadJSONData(config.jsonPath, config.relay, (data) => {
        config.build(config, data, chart); // Pass chart instance to build function
      }, chart);
    } else {
      console.error(`Failed to create chart for element ${config.elementId}`);
    }
  });
}

// Listen for DOM content loaded to initialize the chart and setup theme switching
document.addEventListener('DOMContentLoaded', () => {
  const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
  
  toggleSwitch.addEventListener('change', (e) => {
      currentTheme = e.target.checked ? 'dark' : 'light';
      localStorage.setItem('theme', currentTheme); 
      document.body.classList.toggle('dark-mode', currentTheme === 'dark');
      update_chartsConfig(currentTheme); 
  });

  if (localStorage.getItem('theme') === 'dark') {
      toggleSwitch.checked = true;
      document.body.classList.add('dark-mode');
  }
  update_chartsConfig(currentTheme); 
});
